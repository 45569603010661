<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="libs">
      <div class="top-bar">
        <span class="top-title"
          ><el-button
            icon="el-icon-arrow-left"
            size="small"
            class="mr20"
            @click="toList"
            >返回</el-button
          >应用管理</span
        >
      </div>
      <div class="form-content">
        <el-form
          label-position="top"
          label-width="80px"
          :model="forminfo"
          :rules="infoRules"
          ref="formRef"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="应用名称" prop="AppName">
                <el-input
                  size="small"
                  v-model="forminfo.AppName"
                  placeholder="请输入应用名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="页面路径" prop="PageUrl">
                <el-input
                  size="small"
                  v-model="forminfo.PageUrl"
                  placeholder="请输入页面路径"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="应用图标">
            <div class="upload-warp">
              <span
                class="app-image-libs"
                style="width: 60px; height: 60px; font-size: 24px"
                @click="openIcon"
                ><i
                  v-if="!forminfo.IconId"
                  class="el-icon-plus image-uploader-icon"
                ></i>
                <el-image
                  class="lib-show-image"
                  v-else
                  :src="baseurl + forminfo.IconUrl"
                  fit="cover"
                ></el-image> </span
              ><span class="upload-tips">尺寸要求: 60 * 60</span>
            </div>
          </el-form-item>
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">部门设置</span>
                <el-button type="text" @click="bmDialogVisible = true"
                  >设置</el-button
                >
              </div>
              <div class="tag-warp">
                <el-tag
                  type="warning"
                  closable
                  @close="delbm(index)"
                  v-for="(item, index) in sqlist.sqbm"
                  :key="index"
                  ><i class="fa fa-group">{{ item.bmmc }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">人员设置</span>
                <el-button type="text" @click="ryDialogVisible = true"
                  >设置</el-button
                >
              </div>
              <div class="tag-warp">
                <el-tag
                  closable
                  @close="delry(index)"
                  v-for="(item, index) in sqlist.sqry"
                  :key="index"
                  ><i class="fa fa-user">{{ item.xm }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <div class="ry-top">
                <span class="ry-top-left mr10">角色设置</span>
                <el-button type="text" @click="roleDialogVisible = true"
                  >设置</el-button
                >
              </div>
              <div class="tag-warp">
                <el-tag
                  type="success"
                  closable
                  @close="delrole(index)"
                  v-for="(item, index) in sqlist.sqjs"
                  :key="index"
                  ><i class="fa fa-user-circle-o">{{ item.jsmc }}</i></el-tag
                >
              </div>
            </el-col>
          </el-row>
          <div class="submit-form-btns">
            <el-button
              type="primary"
              class="submit-btn"
              size="small"
              @click="save"
              >保存</el-button
            >
          </div>
        </el-form>
        <sy-bmdialog
          :visible="bmDialogVisible"
          :checkNodes="sqlist.sqbm"
          :checkKeys="sqlist.sqbmStr"
          @ok="bmok"
          @close="bmDialogVisible = false"
        ></sy-bmdialog>
        <sy-roledialog
          :visible="roleDialogVisible"
          :checkNodes="sqlist.sqjs"
          :checkKeys="sqlist.sqjsStr"
          @ok="roleok"
          @close="roleDialogVisible = false"
        ></sy-roledialog>
        <sy-userdialog
          :visible="ryDialogVisible"
          :checkNodes="sqlist.sqry"
          :checkKeys="sqlist.sqryStr"
          @ok="userok"
          @close="ryDialogVisible = false"
        ></sy-userdialog>
        <el-dialog :visible.sync="iconDialogVisible" width="45%" center>
          <span slot="title">
            <h1>图标库</h1>
          </span>
          <div class="uploader">
            <div
              title="点击上传"
              class="image-uploader"
              style="width: 60px; height: 60px; font-size: 24px"
            >
              <el-upload
                :headers="headers"
                :action="uploadurl"
                :on-success="handleSuccess"
                :show-file-list="false"
              >
                <el-image
                  class="lib-show-image"
                  v-if="forminfo.IconUrl"
                  :src="baseurl + forminfo.IconUrl"
                  fit="cover"
                ></el-image>
                <i v-else class="el-icon-plus image-uploader-icon"></i>
              </el-upload>
            </div>
            <span class="upload-tips">尺寸推荐: 60px * 60px</span>
          </div>
          <div class="mt40">
            <h4 class="icon-title">系统图标</h4>
            <el-scrollbar style="height: 330px" class="myscrollbar">
              <ul class="icon-libs" style="padding-left: 0">
                <li
                  v-for="(item, index) in iconlist"
                  :key="index"
                  class="lib-icon"
                  style="width: 60px; height: 60px; font-size: 24px"
                  @click="choose(item.id, item.filePath)"
                >
                  <el-image
                    class="icon-img"
                    :src="baseurl + item.filePath"
                    fit="cover"
                  ></el-image>
                </li>
              </ul>
            </el-scrollbar>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import bmdialog from '../../components/dialog/bmselect.vue'
import roledialog from '../../components/dialog/roleselect.vue'
import userdialog from '../../components/dialog/userselect.vue'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import { getIconList, saveMenu, getFormData } from '../../api/mobile'
export default {
  components: {
    'sy-bmdialog': bmdialog, // 挂载组件
    'sy-roledialog': roledialog,
    'sy-userdialog': userdialog,
  },
  data() {
    return {
      forminfo: {
        ID: '',
        AppName: '',
        IconId: '',
        PageUrl: '',
        BMBH: '',
        JGH: '',
        RoleID: '',
        IconUrl: '',
      },
      bmDialogVisible: false,
      roleDialogVisible: false,
      ryDialogVisible: false,
      iconDialogVisible: false,
      sqlist: {},
      headers: null,
      baseurl: baseUrl,
      uploadurl: baseUrl + 'File/upload',
      iconlist: [],
      infoRules: {
        AppName: [
          { required: true, message: '请输入应用名称', trigger: 'change' },
        ],
        PageUrl: [
          { required: true, message: '请输入页面地址', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.forminfo.ID = this.$route.query.id
    getFormData(this.forminfo.ID).then((res) => {
      if (res.code === 200) {
        this.forminfo.AppName = res.data.appName
        this.forminfo.IconId = res.data.iconId
        this.forminfo.PageUrl = res.data.pageUrl
        this.forminfo.IconUrl = res.data.iconUrl
        this.sqlist.sqbmStr = res.data.sqbmStr
        this.sqlist.sqbm = res.data.sqbm
        this.sqlist.sqjs = res.data.sqjs
        this.sqlist.sqjsStr = res.data.sqjsStr
        this.sqlist.sqry = res.data.sqry
        this.sqlist.sqryStr = res.data.sqryStr
      }
    })
  },
  methods: {
    toList() {
      this.$router.push('/mobile/sy_mobile_menu/index')
    },
    bmDiaConfirm() {
      const sqbm = this.$refs.bmselect.mycheckNodes
      this.sqlist.sqbm = []
      for (var i = 0; i < sqbm.length; i++) {
        this.sqlist.sqbm.push({
          bmbh: sqbm[i].id,
          bmmc: sqbm[i].text,
        })
      }
      this.sqlist.sqbmStr = this.$refs.bmselect.mycheckKeys
      this.bmDialogVisible = false
    },
    delbm(index) {
      this.sqlist.sqbmStr.splice(index, 1)
      this.sqlist.sqbm.splice(index, 1)
      this.$forceUpdate() // 强制刷视图
    },
    roleDiaConfirm() {
      const sqjs = this.$refs.roleselect.mycheckNodes
      this.sqlist.sqjs = []
      for (var i = 0; i < sqjs.length; i++) {
        this.sqlist.sqjs.push({
          id: sqjs[i].id,
          jsmc: sqjs[i].text,
        })
      }
      this.sqlist.sqjsStr = this.$refs.roleselect.mycheckKeys
      this.roleDialogVisible = false
    },
    delrole(index) {
      this.sqlist.sqjsStr.splice(index, 1)
      this.sqlist.sqjs.splice(index, 1)
      this.$forceUpdate() // 强制刷视图
    },
    ryDiaConfirm() {
      const sqry = this.$refs.ryselect.mycheckNodes
      this.sqlist.sqry = []
      for (var i = 0; i < sqry.length; i++) {
        this.sqlist.sqry.push({
          jgh: sqry[i].id,
          xm: sqry[i].text,
        })
      }
      this.sqlist.sqryStr = this.$refs.ryselect.mycheckKeys
      this.ryDialogVisible = false
    },
    delry(index) {
      this.sqlist.sqryStr.splice(index, 1)
      this.sqlist.sqry.splice(index, 1)
      this.$forceUpdate() // 强制刷视图
    },
    btnchangestep(index) {
      this.$refs.carousel.setActiveItem(index)
    },
    bmok(n, k) {
      const sqbm = n
      this.sqlist.sqbm = []
      for (var i = 0; i < sqbm.length; i++) {
        this.sqlist.sqbm.push({
          bmbh: sqbm[i].id,
          bmmc: sqbm[i].text,
        })
      }
      this.sqlist.sqbmStr = k
      this.bmDialogVisible = false
    },
    roleok(n, k) {
      const sqjs = n
      this.sqlist.sqjs = []
      for (var i = 0; i < sqjs.length; i++) {
        this.sqlist.sqjs.push({
          id: sqjs[i].id,
          jsmc: sqjs[i].text,
        })
      }
      this.sqlist.sqjsStr = k
      this.roleDialogVisible = false
    },
    userok(n, k) {
      const sqry = n
      this.sqlist.sqry = []
      for (var i = 0; i < sqry.length; i++) {
        this.sqlist.sqry.push({
          jgh: sqry[i].id,
          xm: sqry[i].text,
        })
      }
      this.sqlist.sqryStr = k
      this.ryDialogVisible = false
    },
    getData() {
      // 获取流程配置数据 已格式化
      var data = []
      data.push({
        BMBH: this.sqlist.sqbmStr ? this.sqlist.sqbmStr.toString() : '',
        JGH: this.sqlist.sqryStr ? this.sqlist.sqryStr.toString() : '',
        RoleID: this.sqlist.sqjsStr ? this.sqlist.sqjsStr.toString() : '',
      })
      return data
    },
    handleSuccess(response, file, fileList) {
      this.forminfo.IconId = response.data.id
      this.forminfo.IconUrl = response.data.filePath
    },
    openIcon() {
      getIconList().then((res) => {
        if (res.code === 200) {
          this.iconlist = res.data
        }
      })
      this.iconDialogVisible = true
    },
    choose(id, url) {
      this.forminfo.IconId = id
      this.forminfo.IconUrl = url
      this.iconDialogVisible = false
    },
    save() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          if (!this.forminfo.IconId) {
            this.$message.error('请上传图标')
            return false
          }
          this.forminfo.BMBH = this.sqlist.sqbmStr
            ? this.sqlist.sqbmStr.toString()
            : ''
          this.forminfo.JGH = this.sqlist.sqryStr
            ? this.sqlist.sqryStr.toString()
            : ''
          this.forminfo.RoleID = this.sqlist.sqjsStr
            ? this.sqlist.sqjsStr.toString()
            : ''
          saveMenu(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.$router.push('/mobile/sy_mobile_menu/index')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
  },
  mounted() {},
}
</script>
<style scoped>
.container {
  background: rgb(255, 255, 255);
  min-height: 100%;
  padding: 62px;
}
.libs {
  max-width: 820px;
  margin: 0px auto;
}
.top-bar {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  line-height: 32px;
  border-bottom: 1px solid #dfe2e8;
  padding-bottom: 20px;
}
.top-title {
  font-size: 16px;
  font-weight: 700;
}
.form-content {
  margin-top: 40px;
}
.upload-warp {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.app-image-libs,
.image-uploader {
  width: 60px;
  height: 60px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #fff;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.app-image-libs:hover,
.image-uploader:hover {
  border: 1px dashed #409eff;
}
.image-uploader-icon {
  color: #409eff;
}
.upload-tips {
  margin-left: 28px;
}
.submit-form-btns {
  margin-top: 58px;
}
.submit-btn {
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  width: 148px;
  padding: 0px;
}
.ry-top-left {
  font-size: 14px;
  color: #606266;
}
.tag-warp {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  padding: 10px;
}
.el-tag {
  margin-right: 10px;
  margin-top: 5px;
}
.diatitle {
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 20px;
}
.yxzbox {
  width: 100%;
}
.yxzitem {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dia-i1 {
  margin-right: 5px;
  color: #e6a23c;
}
.dia-i2 {
  margin-right: 8px;
  color: #409eff;
}
.del-i {
  color: red;
  font-size: 18px;
  cursor: pointer;
}
.lib-show-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.uploader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.icon-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}
.icon-libs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 320px;
  overflow: auto;
  margin-bottom: 20px;
}
.lib-icon {
  margin-top: 18px;
  margin-right: 18px;
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  position: relative;
}
.lib-icon:hover {
  opacity: 1;
}
ol,
ul {
  list-style: none;
}
.icon-img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
ul::-webkit-scrollbar {
  display: none;
}
</style>

import {
  get, post
} from '../utils/http';

// 获取校图标
export function getIconList(data) {
  return get('mobile/SY_Mobile_Menu/GetIconList/', data);
}

// 保存应用信息
export function saveMenu(data) {
  return post('mobile/SY_Mobile_Menu/Save/', data);
}

// 获取应用列表
export function getMenuList() {
  return get('mobile/SY_Mobile_Menu/GetList/');
}

// 获取应用信息
export function getFormData(id) {
  return get('mobile/SY_Mobile_Menu/GetFormData?id=' + id);
}

// 保存分组信息
export function saveGroup(data) {
  return post('mobile/SY_Mobile_Group/Save/', data);
}

// 获取分组列表
export function getGroupList() {
  return get('mobile/SY_Mobile_Group/GetList/');
}

// 删除应用分组
export function deleteGroup(data) {
  return post('mobile/SY_Mobile_Group/Delete', data);
}

// 获取分组详细信息
export function getGroupDetail(id) {
  return get('mobile/SY_Mobile_Group/GetFormData?id=' + id);
}

// 获取未上架应用
export function getIconListToGroup() {
  return get('mobile/SY_Mobile_Menu/GetIconListToGroup/');
}

// 保存分组应用
export function saveGroupMenu(data) {
  return post('mobile/SY_Mobile_Group/SaveGroupMenu', data);
}

// 删除应用里得分组
export function deleteGM(data) {
  return get('mobile/SY_Mobile_Group/DeleteGM/', data);
}

// 保存分组应用排序
export function saveOrder(data) {
  return post('mobile/SY_Mobile_Group/SaveOrder', data);
}
